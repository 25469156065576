import React, { Component } from 'react'
import Link from '../utils/link'
import Locations from '../components/locations'
import LogoLight from '../assets/images/logo-light.png'
import LogoDark from '../assets/images/logo-dark.png'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    const currentScroll = window.pageYOffset
    if (currentScroll > 1) {
      this.setState({ 
        scrolled: true,
      })
    } else {
      this.setState({ 
        scrolled: false
      })
    }
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
    document && document.querySelector('.locations').classList.remove('menu-active')
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
    document && document.querySelector('.locations').classList.toggle('menu-active')
  }

  render() {

    let { offCanvas, scrolled } = this.state

    let { mainNav } = this.props.wp.siteOptions.settings

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (offCanvas) headerClass += ' active'

    return (
      <>
        <header className={headerClass}>
          <div className='header__inner'>
            <div className='header__content'>
              <Link to='/' title='Assistant Sydney' className='header__logo' {...props}>
                <img className='header__logo--light' src={LogoLight} alt='Assistant Sydney' />
                <img className='header__logo--dark' src={LogoDark} alt='Assistant Sydney' />
              </Link>

              <div className="header__menu" >

                <span onClick={this._toggleOffCanvas}>{ offCanvas ? 'close' : 'menu' }</span>

                <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
                  <span className='lines'></span>
                </button>

              </div>

            </div>
          </div>
        </header>
        <div className={`off-canvas ${ offCanvas && 'active' }`}>
          <div className='off-canvas__inner'>

            { mainNav && mainNav.length > 0 &&
              <nav className='off-canvas__nav'>
                <ul>
                  { mainNav.map((el, i) => (
                    <li key={i}>
                      { el.link && <Link to={el.link} key={i} {...props}>{el.text}</Link> }
                    </li>
                  )) }
                </ul>
              </nav>
            }

            <Locations {...this.props} />

          </div>
        </div>
      </>
    )
  }
}

export default Header
