import React, { Component } from 'react'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends Component {
  state = {
    name: '',
    email: '',
    message: '',
  }

  handleChange = e => {
    console.log(e)
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({ result: "success" })
      })
      .catch(error => this.setState({ result: "fail", msg: error }))
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    this.setState({ result: 'success' })
    const data = encode({ 
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    })
    await fetch("https://hooks.zapier.com/hooks/catch/2181815/b09soyh/?"+data)
  }

  render() {
    let props = {
      ref: "form",
      name: "contact",
      className: "form",
      onSubmit: this.handleSubmit,
    }

    if (this.state.result === "success")
      return (
        <p className="contact__success">
          Thanks for getting in contact with us.
          <br /> A member of our team will be in touch soon.
        </p>
      )

    return (
      <form {...props}>
        <div className="form__row form__row--double">

            <label htmlFor="name-field">

            <input
              type="text"
              name="name"
              id="name-field"
              placeholder="enter you name here"
              onChange={this.handleChange}
              required
            />

            <span className="form__label">Your Name</span>

            </label>

            <label htmlFor="email-field">

            <input
              type="email"
              name="email"
              id="email-field"
              placeholder="enter your email here"
              onChange={this.handleChange}
              required
            />
            <span className="form__label">Your Email</span>

            </label>

        </div>

        <div className="form__row">

            <label htmlFor="message-field">

            <textarea
              name="message"
              id="message-field"
              placeholder="enter your message here"
              onChange={this.handleChange}
              required
              rows="1" cols="1"
            />

            <span className="form__label">Your Message</span>

            </label>

        </div>
     
        <div className="form__row form__row--submit">
          <button type="submit" className="btn btn--primary">
            Send message
          </button>
        </div>

      </form>
    )
  }
}

export default Form
